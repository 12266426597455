import styled from "styled-components"
import Img from "gatsby-image"
import theme from "../../themes"
import { device } from "../../themes/device"

const ImageFluid = styled(Img)`
  width: ${props => (props.width ? props.width : "50%")};
  border-radius: ${props => (props.rounded ? "16px" : "0")};
  margin-bottom: 32px;
  border: 5px solid ${props => (props.borderColor ? props.borderColor : "none")};

  @media ${device.laptop} {
    width: ${props => (props.widthMd ? props.widthMd : "100%")};
    margin: ${props => (props.marginMd ? props.marginMd : "0 0 40px 0")};
  }
`

export default ImageFluid
