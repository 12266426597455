import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import H1 from "../components/H1"
import Container from "../components/Container"
import ImageFluid from "../components/ImageFluid"
import panda_and_clock from "../images/panda_and_clock.svg"
import Img from "gatsby-image"

import H2 from "../components/H2"
import P from "../components/P"
import theme from "../themes/"
import { Grid } from "@material-ui/core"

const TagesablaufPage = ({ data }) => (
  <Layout>
    <SEO title="Tagesablauf" />
    <Section>
      <Container
        background={data.image1.childImageSharp.fluid.src}
        backgroundSize={"cover"}
        backgroundPosition={"35% 100%"}
        backgroundPositionSm={"29% 100%"}
        height={"100vh"}
        heightMd={"600px"}
      >
        <div
          style={{
            background: theme.main.colors.white,
            padding: "16px",
            position: "absolute",
            top: "40px",
            left: "0",
          }}
        >
          <H1>Tagesablauf</H1>
        </div>
      </Container>
    </Section>
    <Section>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <H2>Für einen harmonischen Alltag</H2>
            <p>
              Die Eltern können ihr Kind zwischen 6.30 Uhr – 8.30 zu uns
              bringen. Das Frühstück nehmen wir zwischen 8.00 Uhr und 9.00 Uhr
              ein. Die Zeit bis zum Mittagessen wird nach den Bedürfnissen der
              Kinder gestaltet.
            </p>
            <p>
              Im Morgenkreis begrüssen wir uns in der gesamten Gruppe und fragen
              die Kinder nach ihren Wünschen und Bedürfnissen für diesen Tag.
            </p>
          </Grid>
          <Grid item xs={12} md={6} style={{ boxSizing: "border-box" }}>
            <Img
              fluid={data.image2.childImageSharp.fluid}
              style={{
                width: "100%",
                margin: "0 0 40px 0",
                borderRadius: "16px",
                border: `4px solid ${theme.main.colors.greenLight}`,
              }}
            />
            <p>
              Uns ist es wichtig, den Kindern die Natur näher zu bringen. Daher
              verbringen wir mindestens einmal am Tag Zeit im Freien. Zwischen
              11.00 Uhr und 11.15 Uhr ist Abhol- und Bringzeit.
            </p>
          </Grid>
        </Grid>
      </Container>
    </Section>
    <Section>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <H2>Um 11.30 Uhr essen wir alle gemeinsam zu Mittag.</H2>
            <p>
              Die Ruhezeit findet zwischen 12.00 und 13.30 Uhr statt. Zwischen
              13.30 und 14.00 Uhr ist Abhol- und Bringzeit. Der Nachmittag wird
              individuell nach den Bedürfnissen der Kinder und Wochenpläne der
              Kita gestaltet.
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <Img
              fluid={data.image5.childImageSharp.fluid}
              style={{
                width: "100%",
                borderRadius: "16px",
                border: `4px solid ${theme.main.colors.pink}`,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Section>

    <Section>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <H2>Um 16.00 Uhr nehmen wir das Z’vieri ein.</H2>

            <ImageFluid
              rounded
              width={"100%"}
              widthMd={"75%"}
              fluid={data.image4.childImageSharp.fluid}
              borderColor={theme.main.colors.gray}
            />

            <p>
              Nach dem Z’vieri findet das Freispiel statt. Ab 17.00 Uhr können
              die Kinder abgeholt werden. Um 19.00 schliesst die Kita ihre Tore.
              Beim Abholen findet ein persönliches kurzes Übergabegespräch mit
              den Erziehungsberechtigten statt.
            </p>
          </Grid>
        </Grid>
      </Container>
    </Section>
  </Layout>
)

export default TagesablaufPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "kids_walking.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image2: file(relativePath: { eq: "kids_group_circle.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image3: file(relativePath: { eq: "kids_sleeping.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image4: file(relativePath: { eq: "kids_group_playing.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image5: file(relativePath: { eq: "kids_eating.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
